.drawer .MuiPaper-root.MuiDrawer-paper{
    height: 70%;
}

.drawer-container {
    padding-top: 20px;
    margin: 0 auto 50px auto;
}

.form-control {
    width: 100%;
}

.buffer {
    margin: 10px 0 30px !important;
}
