.hero-background {
    width: 100%;
    min-height: 300px;
    padding: 40px 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
}
.hero-background::before {
    content: "";
    background-size: cover;
    background-position: top center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
}
.hero-background.prtracker::before{
    background-image: url('/images/prtracker-banner.jpeg');
}
.hero-background.petweight::before{
    background-image: url('/images/petweight-banner.jpeg');
}

.hero-content {
    position: relative;
}
.hero-title {
    position: relative;
    width: 100%;
    font-weight: 900 !important;
    padding: 10px;
}
.hero-subtitle {
    position: relative;
    font-weight: 600 !important;
    padding: 10px 0;
    width: 100%;
    margin: auto;
}
.hero-button {
    text-decoration: none;
}